



















































































import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/vue';
import { DashboardOptions } from '@uppy/dashboard';
import { random } from 'lodash';
import PtBr from '@uppy/locales/lib/pt_BR';
import Tooltip from '@/components/tooltip.vue';
import Field from '@/components/field.vue';
import WysiwygEditor from '@/components/wysiwyg-editor.vue';
import MultipleEmailField from '@/components/multiple-email-field.vue';
import { IValidationRules } from '@/interfaces/validation-rules.interface';
import EmailModel from '@/models/email.model';
import dayjs from '@/plugins/dayjs';
import Base64AttachmentModel from '@/models/base64attachment.model';

@Component({
  components: {
    Tooltip,
    Field,
    MultipleEmailField,
    WysiwygEditor,
    Dashboard,
  },
})
export default class EmailComposer extends Vue {
  btnAnexo = false;

  valid = false;

  @VModel({ required: true })
  model!: EmailModel;

  @Prop()
  rules!: IValidationRules;

  @Prop()
  acceptedAttachmentTypes!: string[];

  @Prop({ default: false })
  hideCc!: boolean;

  @Prop({ default: false })
  hideSendCopy!: boolean;

  @Prop({ default: true })
  hideSendAttachments!: boolean;

  @Prop({ default: true })
  canEditEmails!: boolean;

  @Prop()
  defaultToEmails!: string[];

  @Prop()
  disableFrom!: boolean;

  @Prop()
  disableSubject!: boolean;

  @Prop({ default: false })
  showAttachmentsUppy!: boolean;

  @Prop()
  extensions!: unknown[];

  uppyInstance: Uppy | null = null;

  dashboardProps: DashboardOptions = {
    inline: true,
    width: '100%',
    height: '250px',
    proudlyDisplayPoweredByUppy: false,
    hideUploadButton: true,
    hideRetryButton: true,
  };

  mounted(): void {
    this.uppyInstance = new Uppy({
      locale: PtBr,
      id: `${dayjs().format()}${random(1, 15)}`,
      autoProceed: false,
      restrictions: {
        maxNumberOfFiles: 10,
        minNumberOfFiles: 1,
        maxFileSize: 10240000, // 10Mb
      },
    }).on('file-added', async (file) => {
      const base64file = await EmailComposer.getBase64(file.data as File);
      const attachment: Base64AttachmentModel = {
        name: file.name,
        extension: file.extension,
        base64: base64file,
      };
      this.model.attachments?.push(attachment);
    }).on('file-removed', async (file) => {
      this.model.attachments = this.model.attachments?.filter((attachment) => attachment.name !== file.name);
    });
  }

  get accept(): string | undefined {
    return this.acceptedAttachmentTypes ? this.acceptedAttachmentTypes.join(', ') : undefined;
  }

  get from(): string[] {
    return this.model.from ? [this.model.from] : [];
  }

  set from(value: string[]) {
    this.model.from = value && value[0];
  }

  get message(): string {
    let completeMessage = this.model.message;
    if (this.model && this.model.metadata) {
      const metadata = JSON.parse(JSON.stringify(this.model.metadata));
      if (metadata[0] && metadata[0].footer) {
        completeMessage += metadata[0].footer;
      }
    }
    return completeMessage;
  }

  set message(newMessage: string) {
    this.model.message = newMessage;
  }

  get hideAttachments(): boolean {
    if (this.model.sendAttachments || this.hideSendAttachments) {
      return false;
    }
    return true;
  }

  public static getBase64(file: File): Promise<string> {
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onload = (ev) => {
        resolve(ev.target?.result as string);
      };
      reader.readAsDataURL(file);
    });
  }
}
